import ConfirmReturn from './pages/ConfirmReturn/ConfirmReturn';
import { Layout } from 'antd';
import './App.less';

const { Header, Content } = Layout;

function App() {
    return (
        <Layout id="app">
            <Header>
                <a className="header-link" href="/">
                    Vodafone - Staðfesting Skila
                </a>
            </Header>
            <Content>
                <ConfirmReturn />
            </Content>
        </Layout>
    );
}
export default App;
